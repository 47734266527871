.block {
  padding: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart {
  width: 100%;
  max-width: 240px;
  height: 100%;
  position: relative;
}

.stat { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h4 {
    font-weight: 400;
    margin: 0;
    font-size: 2.75rem;
  }
  p {
    margin: 0;
    font-weight: 400;
  }
}

.footer {
  padding-top: 1.75rem;
  h4 {
    font-size: 1rem;
    margin: 0;
    font-weight: 400;
  }
}