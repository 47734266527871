@import "../../scss/variables";
@import "../../scss/mixins";

.block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content {
  width: 100%;
  max-width: 460px;
  padding: 2rem;
}

.back-link {
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  text-align: center;
  a {
    text-decoration: underline;
    color: #4A5073;
  }
  svg {
    margin-right: .5rem;
  }
}

.card {
  padding: 2rem 1.5rem;
  display: block;
  margin: 0;
}

.title {
  margin-bottom: 2rem;
  h3 {
    margin: 0;
    text-align: center;
    line-height: 1.3;
    font-weight: 600;
    color: #262B40;
    font-size: calc(1.3rem + 0.6vw);
  }
}

.form-group {
  margin-bottom: 1.5rem;
  label {
    margin-bottom: .5rem;
    font-weight: 600;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.5;
    color: #4A5073;
  }
}

.input-group {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  border: 0.0625rem solid #d1d7e0;
  border-radius: .5rem;
  overflow: hidden;
  span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: .55rem .75rem;
  }
  svg {
    display: inline-block;
    color: #66799e;
    height: 1rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  input {
    width: 100%;
    border: 0;
    text-decoration: none;
    box-shadow: none;
    appearance: none;
    outline: none;
    padding: .55rem .75rem .55rem 0;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    color: #66799e
  }
  input::placeholder {
    color: #66799e
  }
}

.message {
  display: inline-block;
  margin-bottom: .5rem;
}

.submit {
  @include button-clear;
  width: 100%;
  font-weight: 500;
  line-height: 1.5;
  padding: 0.55rem 0.75rem;
  background-color: #262B40;
  border-color: #262B40;
  border-radius: 0.5rem;
  font-size: 1rem;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 8px;
  }
}