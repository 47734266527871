.block {
  padding: 0 .5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.report-item {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  h3, h4, h4 {
    margin: 0;
    display: block;
    line-height: 2;
    font-weight: 400;
    color: #262B40;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.5rem;
  }
}