.block {
  width: 100%;
  // max-width: 600px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  border-bottom: .0625rem solid rgba(46,54,80,.125);
}

.title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  display: block;
}