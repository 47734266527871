@import "../../scss/mixins.scss";
@import "../../scss/variables.scss";

.block {
    display: block;
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    background-color: $primary-color;
    padding-top: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    text-align: right;
    
    @media (min-width: $medium-size) {
        flex: 0 0 $side-nav-width;
    }

    &[data-active="false"]{
        display: none;
    }
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.button {
    @include button-clear;
    padding: 8px;
    display: inline-block;
    margin-bottom: 18px;
    svg {
        font-size: 1.5rem;
    }

    @media (min-width: $medium-size) {
        display: none;
    }
}