@import "../../scss/_variables.scss";

.block {
    @media (min-width: $medium-size) {
        display: flex;
        flex-direction: row;
    }
}

.content {
    width: 100%;
    padding-top: $top-nav-height;
    padding-bottom: 30vh;
    overflow: hidden;
}