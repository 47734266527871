.block {
  width: 100%;
  height: 100%;
  padding: 1.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chart {
  position: relative;
  width: 100%;
  max-width: 240px;
  height: 100%;
}