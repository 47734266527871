.block{
  display: flex;
	flex-direction: row;
	width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  overflow: visible;

  select {
    border: 1px solid #888;
    border-radius: 2px;
    color: #000;
    font-size: .875rem;
    margin: .5rem .5rem;
    overflow: hidden;
    padding-top: 2px;
    padding-bottom: 2px;
    // text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.card {
  padding: .75rem .75rem;
}

.input {
  margin: .5rem .5rem;
}