@import "../../scss/variables";

.table-cont {
  overflow: auto;
}

.title-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem 1.5rem;
  border-bottom: .0625rem solid rgba(46,54,80,.125);
}

.title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: 400;
  display: block;
}

.table {
  text-indent: initial;
  border-spacing: 2px;
  color: #4a5073;
  vertical-align: top;
  border-color: #eaedf2;
  border-collapse: collapse;
  width: 100%;
  th {
    padding: 0.75rem 1.5rem;
    font-size: .875rem;
    font-weight: 700;
    text-align: inherit;
    border: 0 solid #262b40;
    border-bottom-width: .0625rem;
  }
}

.heading {
  background-color: $background-color;
}

.row {
  border: 0 solid $divider-color;
  border-bottom-width: .0625rem;
  
  td {
    font-weight: 600;
    padding: .75rem 1.5rem;
  }
}

