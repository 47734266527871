@import "../../scss/variables.scss";

.block {
    text-align: left;
    margin-bottom: .2rem;
    vertical-align: middle;
    a {
        text-decoration: none;
        display: block;
    }
}

.text {
    color: #eaedf2;
    line-height: 1.6;
}

.icon {
    color: #ffffff;
    min-width: 22px;
    margin-right: .8rem;
}

.content {
    padding: 0.55rem 0.75rem;
    background-color: $primary-color;
    border-color: #eaedf2;
    border-radius: .5rem;

    &:hover {
        background-color: $primary-color-light;
    }
    &[data-active="true"]{
        background-color: $primary-color-light;
    }
}
