@import "../../scss/variables";

.block {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: .5rem;
}

.metric-cards-cont {
  display: grid;
  justify-content: stretch;
  grid-template-columns: auto;
  @media (min-width: 360px){
    grid-template-columns: auto auto;
  }
  @media (min-width: 580px){
    grid-template-columns: auto auto auto;
  }
  @media (min-width: $medium-size) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.detail-cards-cont {
  display: grid;
  justify-content: stretch;
  grid-template-columns: auto;
  align-items: stretch;
  justify-content: stretch;
  @media (min-width: $medium-size) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 1300px) {
    grid-template-columns: 40% 30% 30%;
  }
  @media (min-width: 1660px) {
    grid-template-columns: 35% 20% 20% 25%;
  }
}