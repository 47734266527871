.block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.5rem;
  h3, h4 {
    margin: 0;
    display: block;
    font-weight: 400;
    color: #262B40;
    line-height: 1.3;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1.5rem;
  }
}