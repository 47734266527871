@import "../../scss/mixins.scss";
@import "../../scss/variables.scss";

.block {
    position: fixed;
    width: 100%;
    height: $top-nav-height;
    top: 0;
    background-color: #f5f8fb;
    background-color: rgba(245, 248, 251, 0.66);
    backdrop-filter: blur(20px);
    z-index: 99;
    
    @media (min-width: $medium-size) {
        &[data-active="true"]{
            padding-left: $side-nav-width;
        }
    }
}

.content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;
}

.button {
    @include button-clear;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: .5rem;
    padding: 0.4rem 0.6rem;
    svg {
        font-size: 1.5rem;
        color: rgba(0, 0, 0, 0.7);
    }
}

.logo {
    width: 40px;
    height: 40px;
    margin-left: 1rem;
    margin-right: auto;

    a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
    }
}

.notification {
    margin-left: auto;
    margin-right: 1.5rem;
    svg {
        font-size: 1.25rem;
        color: $primary-color;
    }
}