.block {
  width: 100%;
  padding: .5rem;
}

.account-options {
  padding: .75rem .5rem;
  h1 {
    font-weight: 400;
    color: #262b40;
  }
}