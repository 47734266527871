@import "../../scss/variables.scss";

.block {
  a {
    display: block;
    text-decoration: none;
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 25px;
  background-color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  svg {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.7);
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.name {
  margin-left: .5rem;
  display: block;
  color: $primary-color;
  text-align: end;
  padding: 0;
}