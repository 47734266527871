.def-name {
  h3, p {
    margin: 0;
  }
  h3 {
    font-size: 1rem;
    font-weight: 400;
  }
  p {
    font-size: .875rem;
    font-weight: 500;
  }
}